import * as React from "react"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"
import type { Checkout } from "shopify-buy"

interface StoreProviderProps {
  children: React.ReactNode,
}

// Docs: https://github.com/Shopify/js-buy-sdk
const client: Client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

// interface Checkout {
//   lineItems: any[]; // Replace `any` with a more specific type if available
// }

interface StoreContextValues {
  cart: any[]; // Replace `any` with a more specific type if available
  isOpen: boolean;
  loading: boolean;
  onOpen: () => void;
  onClose: () => void;
  // example variantId: gid://shopify/ProductVariant/47604225474899
  addVariantToCart: (variantId: string, quantity: number) => Promise<void>;
  // example lineItemId: gid://shopify/CheckoutLineItem/476042254748990?checkout=3e1b41a7b5b80307339f07e3dcbb784c
  removeLineItem: (checkoutID: string, lineItemIds: string[]) => Promise<void>;
  updateLineItem: (checkoutID: string, lineItemId: string, quantity: number) => Promise<void>;
  addDiscount: (checkoutId: string, discountCode: string) => Promise<void>;
  client: Client | undefined; // Assuming Client is a class
  checkout: Checkout | undefined;
  didJustAddToCart: boolean;
}

const defaultValues: StoreContextValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: (variantId: string, quantity: number) => { return new Promise<void>(()=>{}) },
  removeLineItem: (checkoutID: string, lineItemIs: string[]) => { return new Promise<void>(()=>{}) },
  updateLineItem: (checkoutID: string, lineItemID: string, quantity: number) => { return new Promise<void>(()=>{}) },
  addDiscount: (checkoutId: string, discountCode: string) => { return new Promise<void>(()=>{}) },
  client: undefined,
  checkout: undefined,
  didJustAddToCart: false,
};

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({ children }: StoreProviderProps) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)

  const setCheckoutItem = (checkout : Checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== '') {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, '')
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  const addVariantToCart = (variantId: string, quantity: number) => {
    setLoading(true)

    const checkoutID = checkout.id

    const quantityInt = quantity

    if (quantityInt == 0) {
      console.warn("Not going to add zero quantity to cart")
      return Promise.resolve()
    }

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: quantityInt,
      },
    ]

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const removeLineItem = (checkoutID: string, lineItemIds: string[]) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, lineItemIds)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (checkoutID: string, lineItemID: string, quantity: number) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { 
        id: lineItemID,
        quantity: quantity
      },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const addDiscount = (checkoutId: string, discountCode: string) => {
    setLoading(true)

    return client.checkout
      .addDiscount(checkoutId, discountCode)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      }, (fail) => {
        console.log("Add discount failed with reason: ", fail)
        return
      })
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        addDiscount,
        checkout,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
